body {
    width: 100%;
    min-height: 100%;
    height: auto;
    background-color: #000;
    overflow-x: hidden;
    padding-bottom: 40px
}

body.home {
    padding-top: 0
}

html {
    width: 100%;
    height: 100%
}

.no-gutter {
    padding-left: 0;
    padding-right: 0
}

img {
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img,
.wp-caption>img,
.wp-caption a>img {
    width: 100%
}

.background-image.fadein,
.fadein {
    opacity: 1
}

.page-header,
.page-header h1 {
    margin-top: 0
}

.page-header {
    border-bottom: 1px solid #000
}

.navbar-brand {
    text-align: center;
    font-size: 12px;
    background: url('../images/abrias_laforest.png') no-repeat center bottom;
    position: relative;
    min-width: 202px;
    height: 170px
}

.navbar-brand span.site-title {
    font-weight: bold;
    bottom: 20px
}

.navbar-brand span {
    display: block;
    line-height: 12px;
    position: absolute;
    min-width: 172px;
    bottom: 5px
}

.background-image {
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: block;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out
}

h1 span {
    font-size: 70px;
    line-height: 113px
}
#gform_submit_button_1 {
    width: 250px;
    height: 50px;
    line-height: 50px;
    padding-left: 5px;
    font-size: 1.5em;
    margin-top: 10px;
    background: #4E4E4E;
    color: #fff;
}
.effect-duke {
    background: -webkit-linear-gradient(-45deg, #000 0, #fff 100%);
    background: -o-linear-gradient(-45deg, #000 0, #fff 100%);
    background: -webkit-linear-gradient(135deg, #000 0, #fff 100%);
    background: -o-linear-gradient(135deg, #000 0, #fff 100%);
    background: linear-gradient(-45deg, #000 0, #fff 100%);
    position: relative;
    overflow: hidden
}

.effect-duke img {
    -webkit-transition: opacity .35s, -webkit-transform .35s;
    -o-transition: opacity 0.35s, -o-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s
}

.effect-duke:hover img {
    opacity: 0.1;
    -webkit-transform: scale3d(2, 2, 1);
    transform: scale3d(2, 2, 1)
}

.effect-duke h2 {
    -webkit-transition: -webkit-transform .35s;
    -o-transition: -o-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: scale3d(.8, .8, 1);
    transform: scale3d(.8, .8, 1);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    position: absolute;
    bottom: 40px;
    font-size: 20px;
    text-transform: uppercase
}

.effect-duke h2 span {
    background: rgba(255, 255, 255, 0.5);
    padding: 15px 15px 15px 40px;
    color: #000
}

.effect-duke:hover h2 {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.figcaption,
.figcaption>a {
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

body.home .tab-content .tabcontent p,
body.page-template-template-custom .tabcontent p,
h1 span,
.bg-white {
    background: rgba(255, 255, 255, 0.5);
    padding: 15px;
    color: #000
}

.navbar-nav>li>a {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #888888;
    background-color: transparent;
    -webkit-box-shadow: inset 0 -5px 0 #000;
    box-shadow: inset 0 -5px 0 #000
}

.format-video iframe,
textarea {
    width: 100%
}

.wrap {
    margin-bottom: 40px
}

.home section {
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 0 15px;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat
}

.home section.viewmoretab {
    margin-top: 15px
}

.home section.viewmoretab h1 {
    margin-top: 0
}

.content {
    padding-top: 170px
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity
}

.carousel-fade .carousel-inner .active {
    opacity: 1
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1
}

.credits {
    margin: 10px 0
}

footer.content-info {
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0
}

@media (min-width:768px) {
    .navbar {
        margin-bottom: 0
    }
    .navbar-collapse {
        margin-bottom: 0;
        bottom: 0;
        position: absolute;
        right: 0
    }
    .navbar-nav>li>a {
        padding: 0 15px 20px;
        font-weight: bold
    }
    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:hover,
    .navbar-default .navbar-nav>.active>a:focus {
        -webkit-box-shadow: inset 0 -5px 0 #000;
        box-shadow: inset 0 -5px 0 #000
    }
    .top-nav-collapse .navbar-brand,
    .top-nav-collapse .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px
    }
    .pull-right-md {
        float: right
    }
}

@media (min-width:767px) {
    .home section {
        background-attachment: scroll
    }
}

.gallery-row {
    padding: 10px 0
}

.aligncenter {
    display: block;
    margin: 10px auto
}

.alignleft,
.alignright {
    margin-bottom: 10px
}

figure.alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%
}

@media (min-width:768px) {
    .alignleft {
        float: left;
        margin-right: 10px
    }
    .alignright {
        float: right;
        margin-left: 10px
    }
}