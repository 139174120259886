// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #000;

$nav-link-padding: 10px;
$navbar-padding-vertical: 10px;
$navbar-height: 39px;
$navbar-default-bg: #fff;
$navbar-default-link-active-bg: transparent;
$navbar-default-color: #000;
$navbar-default-link-color: #000;
$navbar-default-link-active-color: #888;
.navbar-fixed-top{border:none !important;}